import tw from "twin.macro";
import { Controller, useForm } from "react-hook-form";
import PhoneOrEmailLogin from "./PhoneOrEmailLogin";
import { useState } from "react";
import { emailPattern, isPhoneNumberComplete, validateInput } from "@utils/validations";
import Button from "@components/Button";
import { useAuth } from "@context/authContext";

type FormValues = {
  password: string;
  phoneOrEmail: string;
  form: string;
};

const Form = tw.form`flex flex-col items-center gap-4 self-stretch`;
const FormInput = tw.input`bg-really-gray-800 text-really-gray-400 text-lg leading-4 rounded font-medium p-4 self-stretch h-[61px]`;
const initForm = { password: "", phoneOrEmail: "" };
const ErrorMessage = tw.div`w-full text-[13px] text-red mt-[3px]`;

export const LoginForm: React.FC = () => {
  const { loginWithPassword } = useAuth();
  const {
    register,
    control,
    handleSubmit,
    setError,
    watch,
    clearErrors,
    getValues,
    formState: { isSubmitting },
  } = useForm<FormValues>({ defaultValues: initForm });
  const [type, setType] = useState("");
  const [loginError, setLoginError] = useState(false);

  // cause re-render, need this
  const phoneOrEmail = watch("phoneOrEmail");

  const onSubmit = async (payload: FormValues) => {
    try {
      await loginWithPassword({ email: payload.phoneOrEmail, password: payload.password });
    } catch (error) {
      setLoginError(true);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="phoneOrEmail"
        control={control}
        rules={{
          validate: (value: string) => {
            const { type } = validateInput(value);
            if (type === "phone")
              return isPhoneNumberComplete(value) || "phone number not compeleted";
            return emailPattern.test(value) || "incorrect email address";
          },
          required: "this field is required.",
        }}
        render={(form) => {
          const {
            field,
            fieldState: { error },
          } = form;
          const { onChange, name } = field;

          return (
            <PhoneOrEmailLogin
              name={name}
              error={error}
              setError={setError}
              clearErrors={clearErrors}
              onChange={({ type, value }: any) => {
                setLoginError(false);
                setType(type);
                onChange(value);
              }}
            />
          );
        }}
      />
      <div
        className={`flex flex-col w-full gap-4 transition-all duration-700 ease-in-out ${
          type === "email" && emailPattern.test(getValues().phoneOrEmail)
            ? "opacity-100 max-h-screen "
            : "opacity-0 max-h-0"
        }`}
      >
        <FormInput type="password" {...register("password")} placeholder="enter your password" />
        <Button
          loading={isSubmitting}
          onClick={handleSubmit(onSubmit)}
          label="login"
          className="font-[200] h-[55px] w-full bg-really-purple-haze rounded-lg"
        />
      </div>
      {loginError && <ErrorMessage>incorrect email or password</ErrorMessage>}
      {type === "email" && phoneOrEmail.length > 2 && (
        <div
          className={`block bg-really-purple-haze text-16 py-3 px-6 rounded overflow-hidden w-full transition-all duration-700 ease-in-out`}
          // eslint-disable-next-line react/no-unknown-property
          css={phoneOrEmail.length > 3 ? tw`opacity-100 max-h-screen ` : tw`opacity-0 max-h-0 p-0`}
        >
          If this is your first time logging in, please use{" "}
          <a className="underline cursor-pointer" href="/forgot-password">
            this link
          </a>{" "}
          to set your password.
        </div>
      )}
    </Form>
  );
};
