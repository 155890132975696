import {
  createContext,
  useState,
  useContext,
  type FC,
  type ReactNode,
  useCallback,
  useEffect,
} from "react";

import { auth, db } from "firebase";
import { FirebaseError } from "firebase/app";

import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  getAdditionalUserInfo,
  type User,
  setPersistence,
  browserLocalPersistence,
  updatePassword,
  sendEmailVerification,
  EmailAuthProvider,
  linkWithCredential,
  unlink,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { captureException, captureMessage, setUser as setSentryUser } from "@sentry/react";
import { generateRandomPassword } from "@utils/user";

export type ILogin = {
  email: string;
  password: string;
};

export type IReset = {
  email: string;
};

export type IChangePassword = {
  newPassword: string;
};

const AUTH_URL = import.meta.env.VITE_AUTH_URL;

interface AuthUser extends User {
  accessToken: string;
}

interface AuthContextType {
  auth: any;
  user: AuthUser | null;
  setUser: (user: AuthUser) => any;
  loginWithGoogle: () => Promise<void>;
  loginWithPassword: (data: ILogin) => Promise<void>;
  logout: () => Promise<void>;
  resetPassword: ({ email }: IReset) => Promise<void>;
  sendVerificationEmail: (email: string) => Promise<any>;
  reSendVerificationEmail: () => Promise<any>;
  changePassword: ({ newPassword }: IChangePassword) => Promise<void>;
  loginWithToken: () => Promise<any>;
  updateNeedsPasswordUpdate: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const googleProvider = new GoogleAuthProvider();

interface Props {
  children?: ReactNode;
}

export const AuthProvider: FC<Props> = ({ children }) => {
  const [user, _setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const setUser = useCallback((user: User | null) => {
    user && setSentryUser({ email: user.email! || user.phoneNumber!, id: user.uid });
    _setUser(user);
  }, []);

  useEffect(() => {
    setLoading(true);
    setPersistence(auth, browserLocalPersistence)
      .then(() => setUser(auth.currentUser))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  const loginWithGoogle = useCallback(async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const info = getAdditionalUserInfo(res);
      setUser(res.user);
      if (info === null || info.isNewUser) {
        // hack to not allow new users to sign up w/google
        res.user.delete();
        logout();

        captureMessage("Non customer sign up attempt", {
          level: "warning",
          extra: { email: res.user.email },
        });
        // TODO replace alert with error in form or some toast
        alert("User not found. Become a REALLY subscriber!");
      }
    } catch (err) {
      captureException(err, {
        level: "error",
        extra: { message: "loginWithGoogle error" },
      });
      // TODO replace alert with error in form or some toast
      if (err instanceof FirebaseError) console.log(err.message);
    }
  }, []);

  const loginWithPassword = useCallback(async ({ email, password }: ILogin) => {
    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      setUser(res.user);
    } catch (err) {
      captureException(err, {
        level: "log",
        user: {
          email,
        },
        extra: { message: "signInWithEmailAndPassword error" },
      });

      throw err;
    }
  }, []);

  const loginWithToken = useCallback(async () => {
    try {
      const loginStatus = await fetch(`${AUTH_URL}/status`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // This is important
      });
      if (!loginStatus.ok) throw new Error("Unauthorized autologin attempt");

      const { customToken } = await loginStatus.json();
      const userCredential = await signInWithCustomToken(auth, customToken);
      setUser(userCredential.user);
    } catch (error) {
      captureException(error, {
        level: "error",
        extra: { message: "loginWithToken error" },
      });
      throw error;
    }
  }, []);

  const logout = useCallback(async () => {
    setUser(null);
    await fetch(`${AUTH_URL}/logout`, { method: "POST", credentials: "include" });
    await signOut(auth);
  }, []);

  const resetPassword = useCallback(async ({ email }: IReset) => {
    try {
      await sendPasswordResetEmail(auth, email);
      // TODO: use toastr
      // alert('Password reset link sent!');
    } catch (err) {
      captureException(err, {
        level: "error",
        user: {
          email,
        },
        extra: { message: "resetPassword error" },
      });
      if (err instanceof FirebaseError) alert(err);
    }
  }, []);

  const sendVerificationEmail = useCallback(async (email: string) => {
    try {
      if (!auth.currentUser) return;

      if (!auth.currentUser.email) {
        // link
        const credential = EmailAuthProvider.credential(email, generateRandomPassword(12));
        await linkWithCredential(auth.currentUser, credential);
        return await sendEmailVerification(auth.currentUser);
      }

      // need to unlink previous email if newEmail provided
      if (auth.currentUser.email && auth.currentUser.email !== email) {
        try {
          await unlink(auth.currentUser, EmailAuthProvider.PROVIDER_ID);
        } catch {
          console.log("coudn't unlink by some reason");
        } finally {
          // link
          const credential = EmailAuthProvider.credential(email, generateRandomPassword(12));
          await linkWithCredential(auth.currentUser, credential);
        }
      }

      await sendEmailVerification(auth.currentUser);
    } catch (err) {
      captureException(err, {
        level: "error",
        extra: { message: "sendVerificationEmail error" },
      });

      throw err;
    }
  }, []);

  const reSendVerificationEmail = useCallback(async () => {
    try {
      if (!auth.currentUser?.email) return;
      return await sendEmailVerification(auth.currentUser);
    } catch (err) {
      captureException(err, {
        level: "error",
        extra: { message: "reSendVerificationEmail error" },
      });
    }
  }, []);

  const changePassword = useCallback(async ({ newPassword }: IChangePassword) => {
    if (!auth.currentUser) throw new Error("User not found");
    await updatePassword(auth.currentUser, newPassword);
  }, []);

  const updateNeedsPasswordUpdate = useCallback(async () => {
    if (!auth.currentUser) throw new Error("User not found");

    const uid = auth.currentUser.uid;

    await setDoc(doc(db, "users", uid), { needsPasswordUpdate: false }, { merge: true });
  }, []);

  if (loading) return null;

  return (
    <AuthContext.Provider
      value={{
        // @ts-expect-error
        user,
        setUser,
        loginWithGoogle,
        loginWithPassword,
        resetPassword,
        sendVerificationEmail,
        logout,
        loginWithToken,
        changePassword,
        updateNeedsPasswordUpdate,
        reSendVerificationEmail,
        auth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
