export enum NavRoutes {
  Dashboard = "dashboard",
  BillingDevices = "billing-devices",
  PrivacySecurity = "privacy-security",
  Community = "community",
  MemberBenefits = "member-benefits",
  Network = "network",
  Support = "support",
  Settings = "settings",
  Invites = "invites",
  Login = "login",
  ForgotPass = "forgot-password",
  Activation = "activation",
  Actions = "actions",
  WiFiCalling = "wifi-calling",
}
