import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { axiosInstance } from "./api";

const {
  VITE_REACT_APP_FIREBASE_API_KEY,
  VITE_REACT_APP_FIREBASE_AUTH_DOMAIN,
  VITE_REACT_APP_FIREBASE_PROJECT_ID,
  VITE_REACT_APP_FIREBASE_STORAGE_BUCKET,
  VITE_REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  VITE_REACT_APP_FIREBASE_ID,
  VITE_REACT_APP_FIREBASE_MEASUREMENT_ID,
} = import.meta.env;

const firebaseConfig = {
  apiKey: VITE_REACT_APP_FIREBASE_API_KEY,
  authDomain: VITE_REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: VITE_REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: VITE_REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: VITE_REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: VITE_REACT_APP_FIREBASE_ID,
  measurementId: VITE_REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);

let interceptorId: number | undefined; // Store the interceptor ID so we can eject it later

auth.onAuthStateChanged(async (user) => {
  if (user) {
    // Remove any previous interceptor if it exists
    if (interceptorId !== undefined) axiosInstance.interceptors.request.eject(interceptorId);

    interceptorId = axiosInstance.interceptors.request.use(async (config) => {
      config.headers["x-jwt"] = await user.getIdToken();
      return config;
    });
  } else {
    // Remove the interceptor when the user is not logged in
    if (interceptorId !== undefined) {
      axiosInstance.interceptors.request.eject(interceptorId);
      interceptorId = undefined;
    }
  }
});
