import tw from "twin.macro";
import logo from "@assets/icons/logo-white.svg";
import google from "@assets/icons/google-icon.png";
import { LoginForm } from "./LoginForm";
import { useAuth } from "@context/authContext";
import { Navigate } from "react-router-dom";
import { NavRoutes } from "@constants/routes";

const Container = tw.div`flex flex-col justify-center items-center h-screen bg-black`;
const CenterCont = tw.div`w-[342px] md:max-w-sm md:w-full flex flex-col justify-center items-center gap-8 `;
const ReallyLogo = tw.img`w-[170px] col-start-1 row-start-1 block`;
const HeaderCont = tw.div`flex flex-col items-center gap-1 self-stretch`;
const H2Txt = tw.h2`text-3xl font-black leading-[1.875rem] font-bold text-white`;
const SigninCont = tw.div`flex flex-col items-center gap-6 self-stretch`;

const DividerCont = tw.div`flex items-center gap-2 self-stretch`;
const Divider = tw.div`bg-really-gray-200 h-px w-[154px] grow shrink-0 basis-0`;
const DivTxt = tw.div`text-[0.8125rem] font-medium leading-[0.8125rem] text-white`;
const TxtBase = tw.span`text-black text-base font-medium leading-4`;
const SocialCont = tw.div`flex flex-col items-start gap-3 self-stretch`;
const SocialBtn = tw.button`flex p-4 justify-center items-center gap-3 rounded-lg border-really-gray-300 border bg-white self-stretch`;
const GIcon = tw.img`h-6 w-6 col-start-1 row-start-1 block`;
const FooterCont = tw.div`flex justify-center items-center gap-1 self-stretch`;
const FooterTxt = tw.span`text-white text-base font-medium leading-4`;
const FooterTxtBtn = tw.a`text-white text-base font-medium leading-4 underline`;

export const Login = () => {
  const { loginWithGoogle, user } = useAuth();

  if (user) return <Navigate to={`/${NavRoutes.Dashboard}`} replace />;

  return (
    <Container>
      <CenterCont>
        <ReallyLogo src={logo} alt="Really Logo" />
        <HeaderCont>
          <H2Txt>log in to your account</H2Txt>
        </HeaderCont>
        <SigninCont>
          <LoginForm />
          <DividerCont>
            <Divider />
            <DivTxt>OR</DivTxt>
            <Divider />
          </DividerCont>
          <SocialCont>
            <SocialBtn onClick={loginWithGoogle}>
              <GIcon src={google} alt="Google Icon" />
              <TxtBase>sign in with google</TxtBase>
            </SocialBtn>
          </SocialCont>
        </SigninCont>
        <FooterCont></FooterCont>
        <FooterCont>
          <FooterTxtBtn href="/forgot-password">forgot password?</FooterTxtBtn>
          <FooterTxt>no sweat, let's reset</FooterTxt>
        </FooterCont>
      </CenterCont>
    </Container>
  );
};
