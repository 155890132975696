import { IntercomProvider } from "react-use-intercom";
import Layout from "@components/Layout";
import ComingSoon from "@components/pages/ComingSoon";
import { Login } from "@components/Login";
import ForgotPasswordPage from "@components/pages/ForgotPasswordPage";
import NotFoundPage from "@components/pages/NotFoundPage";
import { AuthProvider, useAuth } from "@context/authContext";
import { SubscriptionProvider } from "@context/subscriptionContextProvider";
import { NavRoutes } from "constants/routes";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import ErrorFallback from "@components/ErrorFallback";
import { lazy, Suspense, useLayoutEffect } from "react";
import { CustomerInfoProvider } from "@context/CustomerInfoContext";
import { Spinner } from "@components/common/Spinner";

const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_ID;

// // Lazy load the components
const DashboardPage = lazy(() => import("@components/pages/DashboardPage"));
const BillingDevices = lazy(() => import("@components/pages/BillingDevices"));
const Activation = lazy(() => import("@components/subscription/pages/Activation"));
const EmailActionsPage = lazy(() => import("@components/pages/EmailActionsPage"));
const PrivacyAndSecurity = lazy(() => import("@components/PrivacyAndSecurity"));
const Support = lazy(() => import("@components/pages/Support"));

const router = createBrowserRouter([
  {
    path: NavRoutes.Login,
    Component: Login,
    ErrorBoundary: ErrorFallback,
  },
  {
    path: NavRoutes.ForgotPass,
    Component: ForgotPasswordPage,
    ErrorBoundary: ErrorFallback,
  },
  {
    path: NavRoutes.Actions,
    element: (
      <Suspense
        fallback={
          <div className="w-full h-full flex items-center justify-center">
            <Spinner />
          </div>
        }
      >
        <EmailActionsPage />
      </Suspense>
    ),
    ErrorBoundary: ErrorFallback,
  },
  {
    id: "root",
    path: "/",
    element: (
      <RequireAuth>
        <CustomerInfoProvider>
          <Layout />
        </CustomerInfoProvider>
      </RequireAuth>
    ),
    ErrorBoundary: ErrorFallback,
    children: [
      {
        index: true, // Default component
        element: <Navigate to={NavRoutes.Dashboard} />,
        ErrorBoundary: ErrorFallback,
      },
      {
        path: NavRoutes.Dashboard,
        element: (
          <Suspense
            fallback={
              <div className="w-full h-full flex items-center justify-center">
                <Spinner />
              </div>
            }
          >
            <DashboardPage />
          </Suspense>
        ),
        ErrorBoundary: ErrorFallback,
      },
      {
        path: NavRoutes.BillingDevices,
        element: (
          <Suspense
            fallback={
              <div className="w-full h-full flex items-center justify-center">
                <Spinner />
              </div>
            }
          >
            <BillingDevices />
          </Suspense>
        ),
        ErrorBoundary: ErrorFallback,
      },
      {
        path: NavRoutes.PrivacySecurity,
        element: (
          <Suspense
            fallback={
              <div className="w-full h-full flex items-center justify-center">
                <Spinner />
              </div>
            }
          >
            <PrivacyAndSecurity />
          </Suspense>
        ),
        ErrorBoundary: ErrorFallback,
      },
      {
        path: NavRoutes.Support,
        element: (
          <Suspense
            fallback={
              <div className="w-full h-full flex items-center justify-center">
                <Spinner />
              </div>
            }
          >
            <Support />
          </Suspense>
        ),
        ErrorBoundary: ErrorFallback,
      },
      ...[
        NavRoutes.Community,
        NavRoutes.MemberBenefits,
        NavRoutes.Network,
        NavRoutes.Invites,
        NavRoutes.Settings,
      ].map((path) => ({
        path,
        Component: ComingSoon,
      })),
      {
        path: "*",
        Component: NotFoundPage,
      },
    ],
  },
  {
    id: "activation",
    path: `${NavRoutes.Activation}/*`,
    element: (
      <Suspense fallback={<Spinner />}>
        <RequireAuth>
          <Activation />
        </RequireAuth>
      </Suspense>
    ),
    ErrorBoundary: ErrorFallback,
  },
]);

function RequireAuth({ children }: { children: JSX.Element }) {
  const { user, loginWithToken } = useAuth();
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const autologin = searchParams.get("autologin");
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (autologin) {
      loginWithToken().catch(() => {
        navigate(`/${NavRoutes.Login}`);
      });
    }
  }, []);

  // null would work, but let's start with white fullscreen
  if (autologin && !user) return <div className="h-screen bg-white" />;
  if (!user) {
    // Redirect them to the /login page, but save the current location they were trying to go
    return <Navigate to={`/${NavRoutes.Login}`} state={{ from: pathname + search }} replace />;
  }

  return children;
}

function App() {
  return (
    <AuthProvider>
      <SubscriptionProvider>
        <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
          <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
        </IntercomProvider>
      </SubscriptionProvider>
    </AuthProvider>
  );
}

export default App;
